<template>
  <div class='landlords-room'>
    <div class="container">
      <h2 class="title">{{$t('landlordTitle')}}</h2>
      <h3>{{$t('landlordContent')}}</h3>
      <div class="landlords-room__items">
        <div class="landlords-room__item" v-for="(item, index) in $t('landlordCards')" :key="index">
          <span>{{item.title}}</span>
          <p>{{item.text}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LandlordsRoom'
  }
</script>